import React, { useEffect/*, useState*/ } from 'react';
import PropTypes from 'prop-types';
//import useToken from 'hooks/useToken';

//import Skeleton from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';

import { Modal, Button, Table } from 'react-bootstrap';
import FalconCloseButton from '../../../../../components/common/FalconCloseButton';
import Title from './Title';

//import FalconCardFooterLink from 'components/common/FalconCardFooterLink';


//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import holdTicket from 'assets/img/support-desk/hold-tickets.png';


const ModalDesgloceVentas = (
    {
        setShowModal,
        showModal,

    }
) => {

    //const { token, setToken } = useToken();
    //const [cargando, setCargando] = useState(true);



    useEffect(
        () => {
            //obtenerVentasTotalesAnuales();
        }
        , []);


    return (
        <>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                onShow={() => { }}
                size="xl"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header>

                    <Modal.Title>
                        
                        <Title />
                        
                    </Modal.Title>

                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModal(false); }} />

                </Modal.Header>
                <Modal.Body>
                    {/*{*/}
                    {/*    cargando ?*/}
                    {/*        <>*/}

                    {/*        </>*/}
                    {/*        :*/}
                    {/*        <>*/}


                    {/*        </>*/}
                    {/*}*/}
                    <h4></h4>
                    <Table striped bordered hover>
                        <tr>
                            <td width="40%" className="text-center">
                                <span className="fw-bold"> KPI (Indicadores ) </span>
                            </td>
                            <td width="30%" className="text-center">
                                <span className="fw-bold"> Estadística 8 meses histórico </span>
                            </td>
                            <td width="30%" className="text-center">
                                <span className="fw-bold"> Estadística Nov 2024 </span>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D)</span> Promedio (AVG) ventas por cliente</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td width="40%"><span className="fw-bold">(D)</span> Promedio (AVG) ventas por órden</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                    </Table>

                    <Table striped bordered hover className="mt-2">

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Promedio (AVG) ventas LIC SERV</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Promedio (AVG) ventas NUBE</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Promedio (AVG) ventas CAJAS</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Promedio (AVG) ventas POLIZAS</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                    </Table>

                    <Table striped bordered hover className="mt-2">

                        <tr>
                            <td width="40%"><span className="fw-bold">(D)</span> TOTAL VENTAS MENSUAL</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">AVG $60,853</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$70,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Distribución</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Recompra</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Propia</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                    </Table>

                    <Table striped bordered hover className="mt-2">

                        <tr>
                            <td width="40%"><span className="fw-bold">(N)</span> Leads nuevos por mes</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="60%" className="text-end pe-2">AVG 425</td>
                                        <td width="40%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="60%" className="text-end pe-2">450</td>
                                        <td width="40%" className="text-start ps-2 border-start border-2">---</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td width="40%"><span className="fw-bold">(N & %)</span> Clientes nuevos con órdenes</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="60%" className="text-end pe-2">15.2</td>
                                        <td width="40%" className="text-start ps-2 border-start border-2">0.035%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="60%" className="text-end pe-2">15</td>
                                        <td width="40%" className="text-start ps-2 border-start border-2">0.0352 %</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Clientes con órdenes en recompra</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                        <tr>
                            <td width="40%"><span className="fw-bold">(D & %)</span> Propia</td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                            <td width="30%">
                                <Table className="m-0">
                                    <tr>
                                        <td width="75%" className="text-end pe-2">$3,125.00</td>
                                        <td width="25%" className="text-start ps-2 border-start border-2">55%</td>
                                    </tr>
                                </Table>
                            </td>
                        </tr>

                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModal(false); }} variant='primary' >Cerrar</Button>
                </Modal.Footer>

            </Modal>
            
        </>

    );
};

ModalDesgloceVentas.propTypes = {
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setShowModal: PropTypes.func,
    showModal: PropTypes.func
};

export default ModalDesgloceVentas;
